<script>
import Layout from "@/router/layouts/main";
import Stat from "@/components/widgets/stat";
import Calendar from 'primevue/calendar';

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Stat,
    Calendar,
  },
  data() {
    return {
      statData: [
        {
          type: "line",
          title: "Vendas Realizadas",
          value: "R$ 0,00",
          icon: "bx-dollar-circle",
        },
        {
          type: "line",
          title: "Pedidos",
          value: "0",
          icon: "bx-shopping-bag",
        },
        {
          type: "line",
          title: "Ticket Médio",
          value: "R4 0,00",
          icon: "bx-cart",
        },
        {
          type: "line",
          title: "Taxa de Conversão",
          value: "0%",
          icon: "bx-line-chart",
        },
      ],

      fields: [
        { key: "date", label: "Data" },
        { key: "type", label: "Tipo" },
        { key: "description", label: "Descrição" },
        { key: "value", label: "Valor" },
      ],
      items: null,

      status: "all",
      sortBy: "name",
      sortDesc: false,
      currentPage: "1",
      perPage: "25",
      periodMonth: new Date(),

      br: {
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
      }
    };
  },
  methods: {
  },
  mounted() {
  }
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/sales">Vendas</router-link></li>
      <li class="breadcrumb-item">Lista de Pedidos</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>Lista de Pedidos</h3>
        <p></p>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-6 col-lg-3 col-xl-3">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <b-input-group class="sales-search mb-3">
      <b-form-input placeholder="Pedidos, clientes ou código de rastreio"></b-form-input>
      <b-input-group-append>
        <b-button variant="default">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M19.023 16.977a35.13 35.13 0 0 1-1.367-1.384c-.372-.378-.596-.653-.596-.653l-2.8-1.337A6.962 6.962 0 0 0 16 9c0-3.859-3.14-7-7-7S2 5.141 2 9s3.14 7 7 7c1.763 0 3.37-.66 4.603-1.739l1.337 2.8s.275.224.653.596c.387.363.896.854 1.384 1.367l1.358 1.392.604.646 2.121-2.121-.646-.604c-.379-.372-.885-.866-1.391-1.36zM9 14c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z">
            </path>
          </svg>
          Buscar
        </b-button>
      </b-input-group-append>
    </b-input-group>

    <div id="dropdown" class="d-flex mb-4">
      <b-dropdown variant="outline-light">
        <template #button-content>
          período
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-group>
          <Calendar v-model="periodMonth" view="month" dateFormat="yy-mm-dd" inline :locale="br" style="width: 250px;">
          </Calendar>
        </b-dropdown-group>
      </b-dropdown>
      <b-dropdown variant="outline-light">
        <template #button-content>
          status
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-form><b-form-radio v-model="status" value="all">Todos</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="status" value="pending">Pendente</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="status" value="approved">Aprovado</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="status" value="in_process">Em separação</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="status" value="shipped">Envido</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="status" value="delivered">Entregue</b-form-radio></b-dropdown-form>
      </b-dropdown>
      <b-dropdown variant="outline-light">
        <template #button-content>
          por página
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-form><b-form-radio v-model="perPage" value="10">10 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="25">25 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="50">50 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="100">100 itens</b-form-radio></b-dropdown-form>
      </b-dropdown>
    </div>

    <div class="card">
      <div class="card-body min-vh-50 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <i class="far fa-sad-cry font-size-24"></i><br>
          nenhum pedido encontrato
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.sales-search .form-control {
  height: calc(1.5em + 0.94rem + 22px);
  border-bottom: solid 2px #D1D1E3;
  font-size: 1rem;
}
.sales-search .form-control:focus {
  border: solid 1px #D1D1E3;
  border-bottom: solid 2px #D1D1E3;
  border-right: 0;
}
.sales-search .btn-default,
.sales-search .btn-default:active,
.sales-search .btn-default:focus {
  background: #fff !important;
  border-color: #D1D1E3 !important;
  border-bottom: solid 2px #D1D1E3 !important;
  border-left: 0 !important;
  color: #000 !important;
}
</style>